<template>
  <div>
    <b-card class="wrap-bg">
      <b-card-body
        class="d-flex justify-content-center align-items-center"
        style="min-height: 350px"
      >
        <b-card-text class="text-center">
          <h1 style="color: #7367f0">Dedicated Source Used on Website</h1>
          <span> Popular searches: <b>Attendance, Performance</b> </span>

          <b-form-input
            v-model="search"
            placeholder="Search for anything"
            class="mt-2"
          />
        </b-card-text>
      </b-card-body>
    </b-card>

    <b-row>
      <b-col md="4" v-for="knowledge in knowledges" :key="knowledge.id">
        <b-link
          :to="{
            name: `knowledge-bases/category`,
            params: { category: knowledge.category },
          }"
        >
          <b-card class="mt-2">
            <b-card-body class="text-center">
              <img :src="knowledge.image" alt="pic" class="img-fluid mb-2" />
              <b-card-text style="color: #6e6b7b">
                <h4>{{ knowledge.tittle }}</h4>
                <p class="mt-1">
                  {{ truncateText(knowledge.description, 100) }}
                </p>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.wrap-bg {
  background-color: #e8e6f8;
  background-image: url(https://demos.pixinvent.com/vuexy-vuejs-admin-template-vue2/demo-1/img/banner.c2d23a97.png);
  background-size: cover;
}
</style>

<script>
export default {
  data() {
    return {
      search: "",
      knowledges: [
        {
          id: 1,
          tittle: "How to use HRM",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc ut aliquam aliquam, nunc nisl aliquet nisl, eget aliquam nisl lorem quis nisl. Sed euismod, nunc ut aliquam aliquam, nunc nisl aliquet nisl, eget aliquam nisl lorem quis nisl.",
          category: "hrm",
          image:
            "https://demos.pixinvent.com/vuexy-vuejs-admin-template-vue2/demo-1/img/sales.1172632b.svg",
        },
        {
          id: 2,
          tittle: "How to use Attendance",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc ut aliquam aliquam, nunc nisl aliquet nisl, eget aliquam nisl lorem quis nisl. Sed euismod, nunc ut aliquam aliquam, nunc nisl aliquet nisl, eget aliquam nisl lorem quis nisl.",
          category: "attendance",
          image:
            "https://demos.pixinvent.com/vuexy-vuejs-admin-template-vue2/demo-1/img/sales.1172632b.svg",
        },
      ],
    };
  },
};
</script>